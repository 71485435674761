<template>
  <section>
    <b-card>
      <h3 class="mb-2">{{ mailTemplateForm.title }}</h3>
      <validation-observer ref="mailTemplateFormRef" tag="form" @submit.prevent="validationForm">
        <b-row>
          <b-col cols="12" lg="7">
            <app-form-text-input-itn
              v-model="mailTemplateForm.subject"
              form-name="mail-title"
              :label="$t('mail_template.subject')"
              :languages="$store.state.auth.activelang"
            />
            <validation-provider :name="$t('mail_template.description')">
              <b-form-group>
                <b-form-textarea
                  id="mailDescription"
                  v-model="mailTemplateForm.description"
                  :max-rows="5"
                  :placeholder="$t('mail_template.description')"
                  :rows="5"
                  class="form-area hide-scroll"
                />
              </b-form-group>
            </validation-provider>
            <app-form-text-editor-itn
              v-model="mailTemplateForm.content"
              form-name="mail-content"
              :label="$t('mail_template.content')"
              :languages="$store.state.auth.activelang"
            />
          </b-col>
          <b-col cols="12" lg="5">
            <p>{{ $t('mail_template.new_order') }}</p>
            <div>{{ $t('mail_template.variables') }}</div>
            <b-card class="border variables">
              <b-row v-for="variable in mailVariables" :key="variable.id">
                <b-col cols="4"><b>{{ variable.code }}</b></b-col>
                <b-col cols="">
                  <p>{{ variable.description | trans }}</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <div class="mx-2 mb-2">
          <b-row align-h="end">
            <b-col cols="12" lg="4">
              <app-form-actions :show-reset="false" form-name="mail" @cancel="$router.push({ name: 'mail-template' })" />
            </b-col>
          </b-row>
        </div>
      </validation-observer>
    </b-card>
  </section>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'

import AppFormTextAreaItn from '@/components/AppFormTextAreaItn.vue'
import AppFormTextEditorItn from '@/components/AppFormTextEditorItn.vue'
import { fetchMailRequest, patchMailRequest } from '@/request/globalApi/requests/mailRequests'
import { fetchMailEventsRequest } from '@/request/globalApi/requests/mailEventRequests'
import AppFormActions from '@/components/AppFormActions.vue'

export default defineComponent({
  name: 'MailTemplateEdit',

  components: {
    AppFormTextAreaItn,
    AppFormTextEditorItn,
    AppFormActions,
  },

  setup(_props, ctx) {
    const mailTemplateFormRef = ref(null)

    const mailTemplateForm = ref({
      id: null,
      title: '',
      description: null,
      eventCode: null,
      subject: null,
      content: null,
    })

    const mailVariables = ref([])

    fetchMailRequest(ctx.root.$route.params.id).then(({ data }) => {
      mailTemplateForm.value = data.mailTemplate

      fetchMailEventsRequest({ mailEvent: mailTemplateForm.value.eventCode }).then(({ data: dataEvent }) => {
        mailVariables.value = dataEvent.variables
      })
    })

    const validationForm = () => mailTemplateFormRef.value.validate().then(success => {
      if (success) {
        patchMailRequest(mailTemplateForm.value)
      }
    })

    return {
      mailTemplateFormRef,
      mailTemplateForm,
      mailVariables,
      validationForm,
    }
  },
})
</script>

<style lang="scss" scoped>
.variables {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
