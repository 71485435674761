import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/mail'

export const fetchMailsRequest = queryParams => globalApi.get(END_POINT, { params: queryParams })

export const fetchMailRequest = id => globalApi.get(`${END_POINT}/${id}`)

export const postMailRequest = (mail, message) => globalApi.post(END_POINT, mail, { message })

export const patchMailRequest = (mail, message) => globalApi.patch(`${END_POINT}/${mail.id}`, mail, { message })

export const deleteMailRequest = (id, message) => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${id}`, { message }))
